import { Title } from '@solidjs/meta';
import {
	Button,
	Errors,
	Field,
	FieldDescription,
	Heading,
	Input,
	Label,
	Option,
	Select,
	TextField,
	Container,
} from '@troon/ui';
import { useSubmission } from '@solidjs/router';
import { countryNames } from '@troon/countries';
import { Formspree, formspreeAction, useFormspree } from '@troon/forms';
import { For, Match, Switch } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import dayjs from '@troon/dayjs';
import { IconCalendar } from '@troon/icons/calendar';
import { IconCircleCheckCustom } from '@troon/icons/circle-check-custom';
import { getConfigValue } from '../../modules/config';

export default function DonationRequestPage() {
	const formAction = useFormspree(action);
	const submission = useSubmission(formAction);

	return (
		<>
			<Title>Donation Request | Troon</Title>

			<Container size="small" class="flex flex-col gap-8">
				<Heading as="h1">Donation Request</Heading>

				<Switch>
					<Match when={submission.result}>
						<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
							<IconCircleCheckCustom class="text-8xl" />
							<Heading as="h1" size="h2">
								Request received!
							</Heading>
							<p class="text-lg">Thanks for contacting us! We will get in touch with you shortly.</p>
						</div>
					</Match>
					<Match when>
						<>
							<p>Please fill out the Donation Request Form below and we will review it for consideration.</p>

							<Formspree action={formAction}>
								<TextField name="Event name" required>
									<Label>Event name</Label>
									<Input />
								</TextField>

								<TextField name="Event description" required>
									<Label>Event description</Label>
									<Input multiline />
								</TextField>

								<Field name="Event type" required>
									<Label>Event type</Label>
									<Select>
										<Option disabled selected class="hidden" />
										<Option>Live Auction</Option>
										<Option>Silent Auction</Option>
										<Option>Online Auction</Option>
										<Option>Raffle</Option>
										<Option>Peer to Peer Fundraising Event (Walkathon, etc.)</Option>
										<Option>Other</Option>
									</Select>
								</Field>

								<TextField name="Event date" required>
									<Label>Event date</Label>
									<Input
										type="date"
										min={dayjs().add(15, 'days').format('YYYY-MM-DD')}
										class="ps-8"
										prefixElement={<IconCalendar class="text-lg text-brand" />}
									/>
									<FieldDescription>
										Please provide your request one month prior to your event to allow us time to plan with you and make
										your event a success.
									</FieldDescription>
								</TextField>

								<TextField name="Organization name" required>
									<Label>Organization name</Label>
									<Input />
								</TextField>

								<fieldset>
									<Label>What is the name we can send our donation to?</Label>
									<div class="flex w-full flex-wrap gap-6">
										<TextField name="First name" required class="grow">
											<Label>First name</Label>
											<Input autocomplete="given-name" />
										</TextField>

										<TextField name="Last name" required class="grow">
											<Label>Last name</Label>
											<Input autocomplete="family-name" />
										</TextField>
									</div>
								</fieldset>

								<TextField name="email" required>
									<Label>Email address</Label>
									<Input autocomplete="email" inputMode="email" />
								</TextField>

								<div class="flex w-full flex-wrap justify-stretch gap-x-4 gap-y-2">
									<TextField name="Street address" class="basis-full">
										<Label>Street address</Label>
										<Input autocomplete="street-address" />
									</TextField>
									<TextField name="Address line 2" class="basis-full">
										<Label>Street address line 2</Label>
										<Input autocomplete="street-address" />
									</TextField>
									<TextField name="City" class="grow basis-1/4">
										<Label>City</Label>
										<Input autocomplete="address-level2" />
									</TextField>
									<TextField name="State" class="grow basis-1/4">
										<Label>State</Label>
										<Input autocomplete="address-level1" />
									</TextField>
									<TextField name="Postal Code" class="grow basis-1/4">
										<Label>Postal Code</Label>
										<Input autocomplete="postal-code" />
									</TextField>
									<Field name="Country" class="basis-full">
										<Label>Country</Label>
										<Select autocomplete="country-name">
											<NoHydration>
												<Option disabled selected class="hidden" />
												<For each={countryNames}>{(name) => <Option value={name}>{name}</Option>}</For>
											</NoHydration>
										</Select>
									</Field>
								</div>

								<Field name="Letterhead or proof of 501(c)(3)" required>
									<Label>
										Attach your organization's letterhead or proof of 501(c)(3) status as a single PDF or Word document.
									</Label>
									<Input
										type="file"
										accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
									/>
								</Field>

								<Errors />
								<Button type="submit" class="w-fit">
									Submit
								</Button>
							</Formspree>
						</>
					</Match>
				</Switch>
			</Container>
		</>
	);
}

const action = formspreeAction(getConfigValue('FORMSPREE_PROJECT_ID'), 'donation-request');
